// WaGen___HASHKEY__946cc16d_2022-04-21 15:19:02 !!!!修改时不要修改此行 !!!!


import fetch from '@/utils/fetch';

/*
设计注释:
未提供注释
*/
/* ---
查询工单下用户信息树
*/
export function ykService_getYhxxTreeByGzd(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getYhxxTreeByGzd',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
查询工单下用户/计量点/装置表底信息树
*/
export function ykService_getYhxxTreeInYk(qdrq,ywbh, pagination, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getYhxxTreeInYk',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			},
			pagination : pagination
		}
	})
}

/* ---
用户注销
*/
export function ykService_delYhxxInYkFunction(yhJbxx, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/delYhxxInYkFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : yhJbxx //YhYwJbxx
		}
	})
}

/* ---
计量点删除
*/
export function ykService_deljldxxInYkFunction(yhJldxx, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/deljldxxInYkFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : yhJldxx //YhYwJldxx
		}
	})
}

/* ---
装置表底删除
*/
export function ykService_delzzbdzlxxInYkFunction(yhJldzzbd, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/delzzbdzlxxInYkFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : yhJldzzbd //YhYwJldzzbd
		}
	})
}

/* ---
增加电源
*/
export function ykService_addDyxxInYk(yhJldgx, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/addDyxxInYk',
		method : 'post',
		headers : {
		},
		data : {
			param : yhJldgx //YhYwJldgx
		}
	})
}

/* ---
装表
*/
export function ykService_addYhjldzzdaInYkFunction(paramList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/addYhjldzzdaInYkFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : paramList //List<YhYwJldzzbd>
		}
	})
}

/* ---
业扩装表
*/
export function ykService_ykzbFunction(paramList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykzbFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : paramList //List<YhYwJldzzbd>
		}
	})
}

/* ---
档案增表
*/
export function ykService_dazbFunction(paramList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/dazbFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : paramList //List<YhJldzzbd>
		}
	})
}

/* ---
业扩拆表
*/
export function ykService_ykcbFunction(paramList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykcbFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : paramList //List<YhYwJldzzbd>
		}
	})
}

/* ---
业扩装拆确认
*/
export function ykService_ykzcqrFunction(paramList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykzcqrFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : paramList //List<YhYwJldzzbd>
		}
	})
}

/* ---
业扩报装归档
*/
export function ykService_ykgdFunction(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykgdFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
业扩变更受理
*/
export function ykService_ykbgslFunction(qdrq,ywbh,yhbh,yyqd,dlzh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykbgslFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				yhbh : yhbh, //String
				yyqd : yyqd, //String
				dlzh : dlzh //String
			}
		}
	})
}

/* ---
删除业扩变更受理信息
*/
export function ykService_delbgslxxFunction(qdrq,ywbh,yhbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/delbgslxxFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
小区批量新装导入
*/
export function ykService_xqplxzdrFunction(qdrq,ywbh,yhYwXqxx,YhYwJbxxList, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/xqplxzdrFunction',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				yhYwXqxx : yhYwXqxx, //YhYwXqxx
				YhYwJbxxList : YhYwJbxxList //List<YhYwJbxx>
			}
		}
	})
}

/* ---
业扩变更比较
*/
export function ykService_ykbgbj(ywbh,qdrq, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/ykbgbj',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				ywbh : ywbh, //long
				qdrq : qdrq //Date
			}
		}
	})
}

/* ---
批量更新测量点标识和用户标识
*/
export function ykService_batchUpdateCldbsAndYhbs(param, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/batchUpdateCldbsAndYhbs',
		method : 'post',
		headers : {
		},
		data : {
			param : param //Map<String,Object>
		}
	})
}

/* ---
测量点用户信息
*/
export function ykService_getCldxxAndYhxx(param, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getCldxxAndYhxx',
		method : 'post',
		headers : {
		},
		data : {
			param : param //Map<String,Object>
		}
	})
}

/* ---
更新用户抄表分类
*/
export function ykService_updateYhcbfl(param, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/updateYhcbfl',
		method : 'post',
		headers : {
		},
		data : {
			param : param //Map<String,Object>
		}
	})
}

/* ---
更新业扩装拆表状态
*/
export function ykService_updateYkzcbzt(param, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/updateYkzcbzt',
		method : 'post',
		headers : {
		},
		data : {
			param : param //Map<String,Object>
		}
	})
}

/* ---
生成供电方案答复书
*/
export function ykService_getGdfadfs(qdrq,ywbh,yhbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getGdfadfs',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
获取业扩表单数据
*/
export function ykService_getYkbdsj(qdrq,ywbh,yhbh,bdlx, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getYkbdsj',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				yhbh : yhbh, //String
				bdlx : bdlx //String
			}
		}
	})
}

/* ---
生成供用电合同
*/
export function ykService_getGydht(yhbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/getGydht',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				yhbh : yhbh //String
			}
		}
	})
}

/* ---
校验业扩转发数据
*/
export function ykService_checkYkzfsj(qdrq,ywbh, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/checkYkzfsj',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh //Long
			}
		}
	})
}

/* ---
更新变压器设备状态
*/
export function ykService_updateByqsbzt(qdrq,ywbh,jgbm,jldh,pdbh,zybh,yxzt, meta) {
	return fetch({
		url : 'psdmsykgl/service/YkService/updateByqsbzt',
		method : 'post',
		headers : {
		},
		data : {
			param : {
				qdrq : qdrq, //Date
				ywbh : ywbh, //Long
				jgbm : jgbm, //String
				jldh : jldh, //Long
				pdbh : pdbh, //Integer
				zybh : zybh, //Integer
				yxzt : yxzt //String
			}
		}
	})
}

/* ---
业扩变更受理前校验
*/
export function ykService_ykbgslCheck(yhbh,jgbm,ywlb,ywzl, meta) {
	return fetch({
	 url : 'psdmsykgl/service/YkService/ykbgslCheck',
	 method : 'post',
	 headers : {
	 },
	 data : {
		param : {
		 yhbh : yhbh, //String
		 jgbm : jgbm, //String
		 ywlb : ywlb, //String
		 ywzl : ywzl //String
		}
	 }
	})
 }