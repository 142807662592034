<template>
  <div>
    <van-field
      ref="field"
      v-model="fieldValue"
      :name="name"
      is-link
      :required="required"
      readonly
      :label="label"
      placeholder="选择用电类别"
      :disabled="disabled"
      @click="handleShow"
      :rules="[{ required: required, message: '请选择用电类别' }]"
    >
      sdfsdf
    </van-field>

    <van-popup v-model="show" position="bottom">
      <van-cascader
        v-model="cascaderValue"
        title="选择用电类别"
        :options="options"
        :field-names="fieldNames"
        @close="close"
        @finish="onFinish"
      />
    </van-popup>
  </div>
</template>

<script>
  import { ydlbTreeService_getYdlbTree } from "@/api/psdmsqxgl/services/YdlbTreeServiceAPI";

  import { Cascader } from 'vant';
  export default {
    name: 'YwSelblect',
    props: {
      jgbm: {
        type: String,
        default: ''
      },
      label: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: ''
      },
      value: {
        type: String,
        default: ''
      },
      required: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },

      // 需要排除的业务类型
      exclude: { 
        type: String,
        default: ''
      }
    },
    components:{
      [Cascader.name]: Cascader
    },
    computed: {
    },
    data () {
      return {
        show: false,//Popup是否弹出
        options: [],
        fieldNames: {
          text: 'ydlbmc',
          value: 'ydlbbm',
          children: 'children',
        },
        fieldValue: null,//初始选中数据
        cascaderValue: this.value,
      }
    },
    methods: {
      handleShow() {
        if(!this.disabled) {
          this.show = true;
        }
      },
      filterTreeData(list) {
        let arr = [];
        let keys = this.exclude.split(',');
        for(let i = 0; i < list.length; i++) {
          const item = list[i];
          if (this.value && item.ydlbbm == this.value) {
            this.fieldValue = item.ydlbmc;
          }
          if (keys.indexOf(item.ydlbbm) == -1) {
            item.children = item.children && item.children.length ? this.filterTreeData(item.children) : null;
            arr.push(item);
          }
        }
        return arr;

      },
      // 获取业务分类树
      fetchYWFLData() {
        ydlbTreeService_getYdlbTree(this.jgbm).then(res => {
          let list = res.content.data;

          list = this.filterTreeData(res.content.data);
          this.options = [].concat(list);

          console.log("OPTIONS:", this.options)
        })
      },
      onFinish({value, selectedOptions }) {
        this.show = false;
        this.fieldValue = selectedOptions.map((option) => option.ydlbmc).join('/');
        this.cascaderValue = value;
        this.$emit('input', value);
      },
      close () {//取消
        this.show = !this.show
        this.$emit('cancel')
      }
    },
    watch: {
      value: {
        handler (newVal) {//监听变化初始赋值
          this.cascaderValue = newVal.length ? newVal[newVal.length - 1].ydlbbm : ''
        },
        immediate: true
      }
    },
    mounted() {
      this.fetchYWFLData();
    },
  }
</script>